import React from 'react';
import './Demo.css';

const Demo = () => {
  return (
    <>
    <div className="demo-wrapper">
        <h1>Schedule a demo with today and see how it works</h1>
        <button>Free Demo</button>
    </div>
    </>
  )
}

export default Demo
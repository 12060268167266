import Profile1 from '../img/profile1.jpg';
import Profile2 from '../img/profile2.jpg';
import Profile3 from '../img/profile3.jpg';

export const Reviews = [
    {
        decs: "LedgerMax is helping us in many ways. We now actually know where we stand as a company and how much profits we are making.",
        image: Profile1,
        title: "M. Shoaib Fazil Khan",
        status: "Krave Mart"
    },
    {
        decs: "LedgerMax is helping us in many ways. We now actually know where we stand as a company and how much profits we are making.",
        image: Profile2,
        title: "M. Shoaib Fazil Khan",
        status: "Krave Mart"
    },
    {
        decs: "LedgerMax is helping us in many ways. We now actually know where we stand as a company and how much profits we are making.",
        image: Profile3,
        title: "M. Shoaib Fazil Khan",
        status: "Krave Mart"
    }
]